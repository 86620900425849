$(document).on('turbolinks:load', function() {
  const navbar = $('.navbar');
  const navMenu = navbar.find('.navbar-menu');
  const hamburger = navbar.find('#hamburger');

  function displayMenu(query) {
    if (query.matches) {
      navMenu.removeClass('hidden')
    } else {
      navMenu.addClass('hidden')
    }
  }
  
  const query = window.matchMedia('(min-width: 1024px)')
  displayMenu(query)
  query.addEventListener('change', displayMenu)

  hamburger.on('click', function() {
    navMenu.toggleClass('hidden');
  });

  // Scroll to section
  $('#scroll').on('click', function() {
    const pos = $("#section").offset().top - 36
    window.scrollTo({ top: pos, behavior: "smooth" })
  });

  // Set cookie on portal select
  $('#ph-portal').on('click', function() {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 20)
    document.cookie = `PREF=PH; expires=${date.toUTCString()}; path=/`;
  });

  $('#int-portal').on('click', function() {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 20)
    document.cookie = `PREF=INT; expires=${date.toUTCString()}; path=/`;
  });
});